.btn {
  /*--icon-size: 30px;*/
  /*--font-size: 1em;*/
  --btn-bg-color: transparent;
  --btn-border-radius: 5px;

  position: relative;
  color: var(--text);
  border-radius: var(--btn-border-radius);
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: var(--font-size, 1em);
  background: var(--btn-bg-color);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  outline: none;
  border: none;
  box-sizing: border-box;
  transform: scale(1);
  transition: 0.3s;
}
.btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--btn-bg-color);
  filter: opacity(0.0) brightness(200%) blur(10px);
  transition: 0.3s;
}
.btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 100%;
  height: 100%;
  border: 1px solid var(--text);
  box-sizing: border-box;
  border-radius: var(--btn-border-radius);
  transition: 0.3s;
}
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.1;
}
.btn:hover {
  transform: scale(0.95);
}
.btn:hover::before {
  background-color: var(--btn-bg-color);
  filter: opacity(0.2) brightness(200%) blur(10px);
}
.btn:hover::after {
}
.btn.primary {
  --btn-bg-color: var(--primary);
}
.btn.success {
  --btn-bg-color: var(--success);
}
.btn.danger {
  --btn-bg-color: var(--danger);
}
.btn.warning {
  --btn-bg-color: var(--warning);
}
.btn.info {
  --btn-bg-color: var(--info);
}
.btn.light {
  --btn-bg-color: var(--light);
}
.btn.dark {
  --btn-bg-color: var(--dark);
}
.btn.transparent {
  background: transparent;
  border: none;
}
.btn.transparent::after {
  border: none;
}
.btn.transparent:hover {
  box-shadow: none;
}
.btn.content {
  padding: 0;
  /*display: flex;*/
  justify-content: center;
  align-items: center;
}

.btn.small {
  padding: 5px 10px;
}
.btn.large {
  padding: 15px 30px;
}
.btn.full {
  width: 100%;
}

.btn .btn__text {
  font-size: var(--font-size);
  pointer-events: none;
}

.btn .btn__icon {
  width: var(--icon-size, 30px);
  height: var(--icon-size, 30px);
  box-sizing: border-box;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
