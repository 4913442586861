:root {
    --navItemHeigth: 50px;
}

.nav-item {
    list-style: none;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    height: var(--navItemHeigth);
}

.nav-item:hover {
    background-color: var(--item-hover-bg);
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.nav-item__contents-box {
    animation: fadein 5s ease 0.5s;
    -webkit-animation: fadein 0.5s;
    margin-right: auto;
    width: auto;
    height: auto;
    color: rgb(217, 240, 229);
    white-space: nowrap;
}

.nav-item__icon-box {
    display: inline-block;
}

.nav-item__empty{
    width: 20px;
    height: 20px;
}

.nav-item:hover .nav-item__contents-box, .nav-item__icon-box {
    color: var(--text);
    font-weight: bold;
    fill: var(--text);
    transition: background .15s ease, color .15s ease;
}

.nav-item.selected {
    background-color: var(--item-selected-bg);
}

.nav-item.selected .nav-item__contents-box {
    color: var(--text);
}

/*==================현재 페이지가 NavItem이 가리킨 방향이 아닐경우 (default)==================*/
.nav-item__deactive {
    display: inherit;
    align-items: center;
    font-size: 1rem;
    box-sizing: border-box;
    font-weight: bold;
    gap: 15px;
    padding: 5px 10px;
    background-color: transparent;
}


/*==================현재 페이지가 NavItem이 가리킨 방향일 경우(선택된 경우)==================*/
.nav-item__active {
    align-items: center;
    font-size: 1rem;
    box-sizing: border-box;
    font-weight: bold;
    border: 1px solid rgba(255, 255, 255, 0.18);
    display: flex;
    gap: 15px;
    padding: 5px 10px;
    /*background-color: var(--item-hover-bg);*/
    background-color: var(--primary);
    /*border-radius: 10px;*/
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
}
