.loading__back {
  position: absolute;
  width: 100%;
  height: 100%;
}

.one-div {
  width: 40px;
  height: 40px;
  border: 8px solid #fff;
  border-top-color: #8c8eff;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  z-index: 1;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/*.loading-bar {*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%,-50%);*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  background: transparent;*/
/*  border: 10px solid #3c3c3c;*/
/*  border-radius: 50%;*/
/*  text-align: center;*/
/*  line-height: 111px;*/
/*  font-family: sans-serif;*/
/*  font-size: 15px;*/
/*  color: #fff000;*/
/*  letter-spacing: 3px;*/
/*  text-transform: uppercase;*/
/*  text-shadow: 0 0 20px #fff000;*/
/*  box-shadow: 0 0 20px rgba(0,0,0,.5);*/
/*}*/

/*.loading-bar:before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  top: -3px;*/
/*  left: -3px;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  border: 3px solid transparent;*/
/*  border-top: 5px solid #fff000;*/
/*  border-right: 5px solid #fff000;*/
/*  border-radius: 50%;*/
/*  animation: animateC 2s linear infinite;*/
/*}*/

/*@keyframes animateC {*/
/*  0% {*/
/*    transform: rotate(0deg);*/
/*  }*/

/*  100% {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

/*@keyframes animate {*/
/*  0% {*/
/*    transform: rotate(45deg);*/
/*  }*/

/*  100% {*/
/*    transform: rotate(405deg);*/
/*  }*/
/*}*/