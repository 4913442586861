.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  width: 100%;
  height: var(--cur-header-height);
  position: relative;
  background-color: var(--background-color);

}

.header>.header__menu{
  padding: 5px;
  border-radius: 50%;
}
.header>.header__menu:hover{
  background-color: var(--item-hover-bg);
}
.header>.header__img-btn-container:hover{
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 3.37 );
}