:root {
    --navItemHeigth: 50px;
    --navGroupBasePaddingLeft: 10px;
}

.nav-group,
.nav-group.selected{
    list-style: none;
    display: flex;
    align-items: center;
    color: #fff;
    transition: all 0.3s ease;
    height: var(--navItemHeigth);
}
.nav-group__empty{
    width: 20px;
    height: 20px;
}
.nav-group:hover {
    background-color: var(--item-hover-bg);
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.nav-group__box {
    width: 100%;
    display: inherit;
    align-items: center;
    font-size: 1rem;
    box-sizing: border-box;
    font-weight: bold;
    gap: 15px;
    /*padding: 10px;*/
    padding: 10px 10px 10px var(--navGroupBasePaddingLeft);
    background-color: transparent;
    cursor: pointer;
    height: var(--navItemHeigth);
}


.nav-group__contents-box {
    animation: fadein 5s ease 0.5s;
    -webkit-animation: fadein 0.5s;
    margin-right: auto;
    width: auto;
    height: auto;
    color: rgb(217, 240, 229);
    white-space: nowrap;
}


.nav-group__iconbox {
    display: inline-block;
}

.nav-group:hover .nav-group__contents-box, .nav-group__iconbox {
    color: var(--text);
    font-weight: bold;
    fill: var(--text);
    transition: background .15s ease, color .15s ease;
}

.nav-group__inner {
    display: block;
    width: 100%;
    font-size: 1rem;
    box-sizing: border-box;
    font-weight: bold;
    gap: 15px;
    cursor: pointer;
    -webkit-animation: fadein 0.5s;
    align-items: center;
    background-color: var(--item-selected-bg);
}

.nav-group__more-info {
    transition: transform 0.3s ease;
}

.nav-group__more-info.expanded {
    transform: rotate(180deg);
}

.nav-group.selected {
    background-color: var(--item-selected-bg);
}

.nav-group.selected .nav-group__contents-box {
    color: var(--text);
}

.nav-group__inner a li {
    display: block;
    margin-left: 38px;
    color: rgb(217, 240, 229);
}

.nav-group__inner a:hover li {
    color: var(--text);
}

.nav-group__inner > li > a > .nav-item__icon-box {
    width: 0;
    height: 0;
}

.nav-group__box.selected{
    background-color: var(--item-hover-bg);
}