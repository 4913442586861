@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.popper__tooltip {
    display: inline-block;
    background-color: var(--light);
    font-weight: bold;
    padding: 5px 0;
    /*font-size: 13px;*/
    font-size: 0.7em;
    border-radius: 4px;
    position: relative;
    animation: fadein 5s ease 0.5s;
    -webkit-animation: fadein 0.5s;
    /*z-index: 15;*/
}

.popper__arrow,
.popper__arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

.popper__arrow {
    visibility: hidden;
}

.popper__arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
}

.popper__tooltip[data-popper-placement^='top'] > .popper__arrow {
    bottom: -4px;
}

.popper__tooltip[data-popper-placement^='bottom'] > .popper__arrow {
    top: -4px;
}

.popper__tooltip[data-popper-placement^='left'] > .popper__arrow {
    right: -4px;
}

.popper__tooltip[data-popper-placement^='right'] > .popper__arrow {
    left: -4px;
}

.popper__contents {
    padding: 10px 0;
}

.popper__contents > *:hover {
    background-color: var(--item-hover-bg);
    box-shadow: none;
    transform: scale(100%);
}

.popper__contents > * {
    margin: 5px 0;
    padding: 8px 15px;
    /*font-size: 10px;*/
    font-size: 0.7em;
    font-weight: bold;
    border: none;
}

.popper__contents > div {
    color: var(--text-contents);
    font-family: 'Lato', sans-serif;
}

.popper__contents button {
    display: block;
    color: var(--text-warning);
    border: none;
    width: 100%;
    border-radius: 0;
    font-family: 'Lato', sans-serif;
    box-shadow: none;
}
.popper__contents button::after {
    border: none;
}
