
.three-way-layout {
  /*width: 100vw;*/
  width: 100%;
  /*width: var(--cur-layout-width, 100%);*/
  height: max(100vh, 100%);
  min-height: 100vh;
  box-sizing: border-box;
  --cur-layout-width: 100%;
  --cur-side-nav-width: 255px;
  --cur-header-height: 50px;
  --side-nav-hover-width: 255px;
}

.nav-layout {
  /*background-color: var(--primary);*/
  width: var(--cur-side-nav-width, 0);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.3s;
}

.nav-layout--slim-hover {
  width: var(--side-nav-hover-width, 0);
}

.nav-layout--hide .nav {
  display: none;
}

.main-layout {
  width: calc(var(--cur-layout-width) - var(--cur-side-nav-width, 0));
  padding-left: var(--cur-side-nav-width, 0);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transition: 0.3s;
}

.header-layout {
  position: fixed;
  top: 0;
  height: var(--cur-header-height, 0);
  width: calc(var(--cur-layout-width) - var(--cur-side-nav-width, 0));
  box-sizing: border-box;
  transition: 0.3s;
}

.content-layout {
  /*background-color: var(--success);*/
  width: calc(var(--cur-layout-width) - var(--cur-side-nav-width, 0));
  padding-top: var(--cur-header-height, 0);
  transition: 0.3s;
  height: max(calc(100vh - var(--cur-header-height, 0)), 100%);
}




