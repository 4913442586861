@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Nanum+Myeongjo:wght@400;700;800&family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

:root {
  --primary: #316CF4;
  --secondary: #FFF7D6;
  --success: #408558;
  --danger: #CB444A;
  --warning: #F6C344;
  --info: #4D8AF0;
  --light: #F8F9FA;
  --dark: #1E1E1E;
  --body: #1F2125; /*투명이 아닌 바닥 종류에 사용*/
  --text: #FFF; /*기본 글자 색상*/
  --text-contents : rgb(80, 186, 140); /*contents 글자 색상*/
  --text-warning : rgb(242, 48, 23); /*warning 글자 색상*/
  --placeholder-color: #A0A0A0; /*placeholder 색상*/
  --background-color: #1D2024; /*기본 바탕 색상 -- 쓰지마세요*/
  --font-size: 12px; /*기본 폰트 사이즈*/
  --selected : skyblue;   /*선택되었을 때 색상*/
  --item-bg: #252525;
  --item-hover-bg: #2C2C2C;
  --item-selected-bg: #474747;
  --link-color: #FFA500;
}

/*FONT*/

@font-face {
  font-family: 'MICEGothic Bold';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2206-01@1.0/MICEGothic Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

* {
  font-family: 'Noto Sans KR', sans-serif;
}

html {
  background: var(--background-color);
}

body {
  margin: 0;
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap'); */
.ff-noto-sans{
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 12px;
}

.ff-nanum-myeongjo{
  font-family: 'Nanum Myeongjo', serif;
}
.ff-black-han-sans {
  font-family: 'Black Han Sans', sans-serif;
}
.ff-google-sans {
  font-family: "Google Sans",Roboto,Arial,sans-serif;

}


/*RESET*/
body {
  margin: 0;
}
a {
  text-decoration: none;
}
input[type=text] {
  border: none;
  outline: none;
  /*background-color: transparent;*/
}
button {
  cursor: pointer;
  padding: 0;
}
ul, ol, li {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
dl,dd {
  margin: 0;
}

