
.Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--icon-size, 100%);
  height: var(--icon-size, 100%);
  box-sizing: border-box;
}
.Icon svg {
  width: 100%;
  height: 100%;
  fill: var(--text);
}
.Icon .circle {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.Icon.success .circle {
  background: var(--success);
}
.Icon.warning .circle {
  background: var(--warning);
}
.Icon.danger .circle {
  background: var(--danger);
}
.Icon.info .circle {
  background: var(--info);
}
.Icon.primary .circle {
  background: var(--primary);
}
.Icon.light .circle {
  background: var(--light);
}
.Icon.dark .circle {
  background: var(--dark);
}
.Icon.transparent .circle {
  background: transparent;
}