.img-btn__btn {
    border-radius: 50%;
    overflow: hidden;
    /*position: relative;*/
}

.img-btn__btn>.img-box{
    display: inline-block;
    /*position: absolute;*/
    width: 100%;
    height: 100%;
    background-color: white;
}