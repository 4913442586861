.nav {
    background-color: var(--body);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.nav__header a {
    color: var(--text);
    margin: auto;
}

.nav__header {
    display: flex;
    width: 100%;
    height: var(--cur-header-height, 0);
}

.nav__body {
    width: 100%;
    height: auto;
    overflow-y: auto;
}

.nav__body::-webkit-scrollbar {
    width: 5px;
}
.nav__body::-webkit-scrollbar-track {
    background: transparent;
}
.nav__body::-webkit-scrollbar-thumb {
    background: var(--item-selected-bg);
    /*border-radius: 15%;*/
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.nav__footer {
    width: 100%;
    height: 50px;
    margin-top: auto;
    background-color: var(--item-selected-bg);
}

.nav__footer:hover {
    background-color: var(--item-hover-bg);
}

.nav__footer button {
    width: 100%;
    height: 100%;
    position: relative;
}

.nav__footer button svg {
    position: relative;
    fill: var(--placeholder-color);
}

.nav__footer button:hover svg {
    fill: var(--text);
}

.nav__footer .btn > div {
    position: absolute;
    right: 0;
    height: inherit;
}

.nav__footer button:hover {
    color: var(--text);
    font-weight: bold;
    fill: var(--text);
}


.NavControl {
    width: 100%;
}

.nav__footer .nav__footer-icon{
    width: auto;
    height: auto;
}