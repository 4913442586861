.logo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.logo__txt {
  font-size: 2rem;
  font-weight: bold;
}